.p-tritronik-timeline.p-datatable .p-datatable-thead > tr > th {
    font-size: 12px;
    padding: 0.5rem 0.5rem;
}
.p-tritronik-timeline.p-datatable .p-datatable-tbody > tr {
    font-size: 12px;
}

.p-tritronik-timeline.p-datatable .p-datatable-tbody > tr > td {
    padding: 0.5rem 0.5rem;
}
